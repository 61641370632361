export const heartColors = [
  "#fc3ad8",
  "#c53df7",
  "#f7e522",
  // "#fc4c46",
  "#10f204",
  // "#f21d2b",
  "#11e0dc",
  "#31a9f9",
]
